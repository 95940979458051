export const dataZoneResellers = [
  {
    zone: "CABA",
    lat: -34.6158238,
    lng: -58.4332985,
    zoom: 12,
  },
  {
    zone: "Gran Buenos Aires",
    lat: -34.6255119,
    lng: -58.4722263,
    zoom: 11,
  },
  {
    zone: "Buenos Aires",
    lat: -37.1552089,
    lng: -63.1923911,
    zoom: 7,
  },
  {
    zone: "Chaco",
    lat: -26.0688138,
    lng: -62.0210532,
    zoom: 7,
  },
  {
    zone: "Córdoba",
    lat: -32.2258144,
    lng: -66.0146119,
    zoom: 7,
  },
  {
    zone: "Entre Ríos",
    lat: -32.0993727,
    lng: -59.2880279,
    zoom: 7,
  },
  {
    zone: "Mendoza",
    lat: -34.7873176,
    lng: -68.5311155,
    zoom: 7,
  },
  {
    zone: "Neuquén",
    lat: -38.6051647,
    lng: -69.9818719,
    zoom: 7,
  },
  {
    zone: "La Rioja",
    lat: -29.853734,
    lng: -67.5289487,
    zoom: 7,
  },
  /*   {
    zone: "Santa Fe",
    lat: -31.1915329,
    lng: -60.8802759,
    zoom: 7,
  }, */
  {
    zone: "Tierra del Fuego",
    lat: -53.831729,
    lng: -68.8656159,
    zoom: 7,
  },
  {
    zone: "Tucumán",
    lat: -26.8328589,
    lng: -65.2225936,
    zoom: 8,
  },
];

export const dataResellers = [
  {
   name: "AFF AUDIO",
   lat: -34.6452360922279,
   lng: -58.5202837730743,
   country: "Argentina",
   city: "CABA",
   province: "CABA",
   zone: "CABA",
   address: "Av. Lisandro de la Torre 600",
   cp: "C1408",
   phone: "(011) 5727-4058",
   mobile: "11 57274058",
   mail: "affsq@hotmail.com",
   website: "https://www.aff.com.ar/",
   Instagram: "affaudio"
  },
  {
   name: "AMERICAR'S",
   lat: -34.62266835885869,
   lng: -58.39469389815205,
   country: "Argentina",
   city: "CABA",
   province: "CABA",
   zone: "CABA",
   address: "Av. San Juan 2094, San Cristobal",
   cp: "C1232AAP",
   phone: "(011) 7079-9999",
   mobile: null,
   mail: "americarsml@gmail.com",
   website: "https:\/\/www.americars.com.ar\/",
   Instagram: "americarsaudio"
  },
  {
   name: "AUDIO SECRETS S.A.",
   lat: -34.59935695934812,
   lng: -58.44946548647664,
   country: "Argentina",
   city: "CABA",
   province: "CABA",
   zone: "CABA",
   address: "Av. Warnes 929, Villa Crespo",
   cp: "C1414AAJ",
   phone: "(011) 4514-9933\/4856-6600",
   mobile: null,
   mail: "info@audiosecrets.com.ar",
   website: "http:\/\/www.audiosecrets.com.ar\/",
   Instagram: "audiosecrets"
  },
  {
   name: "AUDIO SECRETS S.A.",
   lat: -34.59935295933821,
   lng: -58.4505318864766,
   country: "Argentina",
   city: "CABA",
   province: "CABA",
   zone: "CABA",
   address: "Av. Warnes 990, Villa Crespo",
   cp: "C1414AAJ",
   phone: "(011) 4514-9932\/4855-5568",
   mobile: null,
   mail: "info@audiosecrets.com.ar",
   website: "http:\/\/www.audiosecrets.com.ar\/",
   Instagram: "audiosecrets"
  },
  {
   name: "AUDIO SECRETS S.A.",
   lat: -34.59745054932402,
   lng: -58.455507544147984,
   country: "Argentina",
   city: "CABA",
   province: "CABA",
   zone: "CABA",
   address: "Av. Warnes 1401, Villa Crespo",
   cp: "C1416CQB",
   phone: "(011) 4514-9934\/4514-9935",
   mobile: null,
   mail: "info@audiosecrets.com.ar",
   website: "http:\/\/www.audiosecrets.com.ar\/",
   Instagram: "audiosecrets"
  },
  {
   name: "AUDIO TRENDS",
   lat: -34.623647533804565,
   lng: -58.40148720181849,
   country: "Argentina",
   city: "CABA",
   province: "CABA",
   zone: "CABA",
   address: "Av. San Juan 2646, San Cristobal",
   cp: "C1232AAV",
   phone: "(011) 4941-3933",
   mobile: null,
   mail: "info@audiotrends.com.ar",
   website: "https:\/\/www.audiotrendsshop.com.ar\/",
   Instagram: "audio_trends"
  },
  {
   name: "AUTORADIO NELSON",
   lat: -34.62247674478282,
   lng: -58.38667747298282,
   country: "Argentina",
   city: "CABA",
   province: "CABA",
   zone: "CABA",
   address: "Av. San Juan 1470, San Cristobal",
   cp: "C1148AAP",
   phone: "(011) 4306-0327\/4305-1683",
   mobile: null,
   mail: "info@nelsonautoradio.com.ar",
   website: "https:\/\/www.nelsonautoradio.com.ar\/",
   Instagram: "nelsonautoradio"
  },
  {
   name: "PUNTA WARNES",
   lat: -34.59769165527596,
   lng: -58.455725081348305,
   country: "Argentina",
   city: "CABA",
   province: "CABA",
   zone: "CABA",
   address: "Av. Warnes 1402, Villa Crespo",
   cp: 1416,
   phone: "(011) 45860402\/0474",
   mobile: null,
   mail: "puntawarnes@fibertel.com.ar",
   website: null,
   Instagram: "puntawarnes"
  },
  {
   name: "AUDIO BAIRES",
   lat: -34.547775733298245,
   lng: -58.72592132880664,
   country: "Argentina",
   city: "San Miguel",
   province: "Buenos Aires",
   zone: "Gran Buenos Aires",
   address: "Av Gaspar Campos 3568, San Miguel",
   cp: 1663,
   phone: null,
   mobile: "11 52506450",
   mail: "audiobaires@hotmail.com",
   website: "https:\/\/www.audiobaires.com\/",
   Instagram: "audiobaires"
  },
  {
   name: "AUTORADIO ESCALADA S.R.L.",
   lat: -34.734317974779735,
   lng: -58.397048672980056,
   country: "Argentina",
   city: "R. de Escalada, Lanús",
   province: "Buenos Aires",
   zone: "Gran Buenos Aires",
   address: "Av. H. Yrigoyen 6824",
   cp: "B1826DRP",
   phone: "(011) 4248-9389",
   mobile: null,
   mail: "arescalada@arescalada.com.ar",
   website: "https:\/\/www.arescalada.com.ar\/",
   Instagram: "arescalada.78"
  },
  {
   name: "AUTORADIO ESCALADA S.R.L.",
   lat: -34.5994785283206,
   lng: -58.5021706865665,
   country: "Argentina",
   city: "CABA",
   province: "CABA",
   zone: "CABA",
   address: "Av. Francisco Beiró 3499",
   cp: 1419,
   phone: "(011) 4502-5977 \/ 4503-1056",
   mobile: null,
   mail: "arescalada.arescalada@gmail.com",
   website: "https:\/\/www.arescalada.com.ar\/",
   Instagram: "arescalada.78"
  },
  {
   name: "CAR STORE BELGRANO",
   lat: -34.5526519726031,
   lng: -58.4557980423859,
   country: "Argentina",
   city: "CABA",
   province: "CABA",
   zone: "CABA",
   address: "11 de Septiembre de 1888 2739",
   cp: "C1428ARQ",
   phone: "(011) 4783-1414",
   mobile: "11 64472550",
   mail: "info@carstore.com.ar",
   website: "https:\/\/www.carstorebelgrano.com\/",
   Instagram: "belgranocarstore"
  },
  {
   name: "MUNDO JW",
   lat: -34.623753076522,
   lng: -58.4839441865658,
   country: "Argentina",
   city: "CABA",
   province: "CABA",
   zone: "CABA",
   address: "Pasaje Rodrigo de Ibarrola 3460",
   cp: "C1407",
   phone: "(011) 2145-9148",
   mobile: "11 38013624",
   mail: null,
   website: "https://www.mundojw.com/",
   Instagram: "mundojw.ok"
  }, 
  {
   name: "CAR STORE MARTINEZ",
   lat: -34.505907407310076,
   lng: -58.53608284415023,
   country: "Argentina",
   city: "Martinez",
   province: "Buenos Aires",
   zone: "Gran Buenos Aires",
   address: "Av. Fondo de la Legua 2028",
   cp: "B1640EEO",
   phone: "(011) 4766-1987\/4763-8248",
   mobile: null,
   mail: "carstore01@gmail.com",
   website: "https:\/\/www.carstoremartinez.com.ar\/",
   Instagram: "carstore.martinez"
  },
  {
   name: "CUSTOM SOUNDS",
   lat: -34.49225245508524,
   lng: -58.54816771531495,
   country: "Argentina",
   city: "San Isidro",
   province: "Buenos Aires",
   zone: "Gran Buenos Aires",
   address: "Av. Marquez 2963",
   cp: "B1642GFQ",
   phone: "(011) 4735-6768 4735-1018",
   mobile: null,
   mail: "info@customsounds.com.ar",
   website: "https:\/\/www.customsounds.com.ar\/",
   Instagram: "customsoundsar"
  },
  {
   name: "GABRIEL FERRARO AUTORADIO",
   lat: -34.76695555656462,
   lng: -58.40375145763667,
   country: "Argentina",
   city: "Lomas de Zamora",
   province: "Buenos Aires",
   zone: "Gran Buenos Aires",
   address: "Av. H. Yrigoyen 9572",
   cp: "B1832BRO",
   phone: "(011) 4244-1965\/4243-4188",
   mobile: null,
   mail: "gabrielferraro@gfautoradio.com.ar",
   website: "https:\/\/www.gfautoradio.com.ar\/",
   Instagram: "gf_autoradio"
  },
  {
   name: "INTEGRAL DESIGN",
   lat: -34.433851208778385,
   lng: -58.55868821531644,
   country: "Argentina",
   city: "San Fernando",
   province: "Buenos Aires",
   zone: "Gran Buenos Aires",
   address: "Alvear 766",
   cp: "B1646EZB",
   phone: "(011) 4725 2142",
   mobile: null,
   mail: "ventas@integraldesign.com.ar",
   website: "https:\/\/www.integraldesign.com.ar\/",
   Instagram: "ventasintegraldesign"
  },
  {
   name: "PLANET CAR CENTER",
   lat: -34.51412087035593,
   lng: -58.477240372985584,
   country: "Argentina",
   city: "Vicente Lopez",
   province: "Buenos Aires",
   zone: "Gran Buenos Aires",
   address: "Av Del Libertador 1394",
   cp: 1638,
   phone: "(011) 4797-7101",
   mobile: "11 64404488",
   mail: "ventas@planetcarcenter.com.ar",
   website: "https://www.planetcarcenter.com.ar/",
   Instagram: "planetcarcenter"
  },
  {
   name: "SONOCAR",
   lat: -34.660065958152956,
   lng: -58.55115070181762,
   country: "Argentina",
   city: "Ramos Mejía",
   province: "Buenos Aires",
   zone: "Gran Buenos Aires",
   address: "Pringles 2054",
   cp: null,
   phone: null,
   mobile: "11 5337 0116",
   mail: null,
   website: null,
   Instagram: "sonocarargentina"
  },
  {
   name: "ALTOVOLUMEN.COM",
   lat: -38.73160085283984,
   lng: -62.27034199986125,
   country: "Argentina",
   city: "Bahía Blanca",
   province: "Buenos Aires",
   zone: "Buenos Aires",
   address: "Angel Brunel 1360",
   cp: 8000,
   phone: null,
   mobile: "291 4326919",
   mail: "ventas@altovolumen.com",
   website: "https:\/\/altovolumen.com\/",
   Instagram: "altovolumen"
  },
  {
   name: "AUTO RADIO MM",
   lat: -35.575068595660156,
   lng: -58.008353030629856,
   country: "Argentina",
   city: "Chascomus",
   province: "Buenos Aires",
   zone: "Buenos Aires",
   address: "Av. Hipólito Yrigoyen 83",
   cp: 7130,
   phone: 2241422905,
   mobile: "+54 9 224144-6352",
   mail: "autoradiomm@hotmail.com",
   website: null,
   Instagram: null
  },
  {
   name: "FEDERICO CAR AUDIO",
   lat: -37.99519476018993,
   lng: -57.561746930566194,
   country: "Argentina",
   city: "Mar Del Plata",
   province: "Buenos Aires",
   zone: "Buenos Aires",
   address: "Moreno 3874",
   cp: 7600,
   phone: "(0223) 4944326",
   mobile: null,
   mail: "federicocaraudio@gmail.com",
   website: "https:\/\/www.federicocaraudio.com.ar\/",
   Instagram: "federicocaraudio"
  },
  {
   name: "SGM HIGH PERFORMANCE",
   lat: -34.1727174746481,
   lng: -58.960575417172215,
   country: "Argentina",
   city: "Campana",
   province: "Buenos Aires",
   zone: "Buenos Aires",
   address: "Iriart 816",
   cp: "B2804BHR",
   phone: "(03489) 43-7513",
   mobile: null,
   mail: "info@sgm1.com.ar",
   website: "https:\/\/www.sgm1.com.ar\/",
   Instagram: "sgmhighperformance"
  },
  {
   name: "LUCAS CAR AUDIO",
   lat: -31.43257987541877,
   lng: -64.15878817306057,
   country: "Argentina",
   city: "Córdoba Capital",
   province: "Córdoba",
   zone: "Córdoba",
   address: "Av Sabattini 2043",
   cp: 5006,
   phone: 3514570337,
   mobile: null,
   mail: "consultas@lucascaraudio.com",
   website: "https:\/\/lucascaraudio.ar\/",
   Instagram: "lucascaraudio"
  },
  {
   name: "BASS BOX AUDIO CAR",
   lat: -38.97203695072071,
   lng: -68.045258101704,
   country: "Argentina",
   city: "Neuquén Capital",
   province: "Neuquén",
   zone: "Neuquén",
   address: "Int. Pedro Linares 1608, Bº Belgrano",
   cp: 8300,
   phone: "(0299) 4401302",
   mobile: null,
   mail: "bassboxmail@gmail.com",
   website: null,
   Instagram: "bassboxautoradio"
  },
  {
   name: "AUTO STORE",
   lat: -29.420349083433386,
   lng: -66.85258591584889,
   country: "Argentina",
   city: "Ciudad de La Rioja",
   province: "La Rioja",
   zone: "La Rioja",
   address: "Av Facuando Quiroga 358",
   cp: 5300,
   phone: "(0299) 4401302",
   mobile: null,
   mail: null,
   website: null,
   Instagram: "autostore_ok"
  },
  {
   name: "JK POLARIZADOS",
   lat: -53.792758552985305,
   lng: -67.73241739939883,
   country: "Argentina",
   city: "Río Grande",
   province: "Tierra del Fuego",
   zone: "Tierra del Fuego",
   address: "Islas Malvinas 2455",
   cp: "V9420",
   phone: "(02964) 42-2599",
   mobile: null,
   mail: null,
   website: null,
   Instagram: null
  },
  {
   name: "SANTA FE AUDIO CAR",
   lat: -26.815080905547756,
   lng: -65.22549718850566,
   country: "Argentina",
   city: "San Miguel De Tucumán",
   province: "Tucumán",
   zone: "Tucumán",
   address: "Santa Fe 2028",
   cp: 4000,
   phone: 3814327144,
   mobile: null,
   mail: "santafeaudiocarjuan@yahoo.com.ar",
   website: null,
   Instagram: "santafeaudiocar"
  },
  {
   name: "ZONIC AUTORADIO",
   lat: -34.8576548552055,
   lng: -58.3909302019056,
   country: "Argentina",
   city: "Longchamps",
   province: "Buenos Aires",
   zone: "Buenos Aires",
   address: "Av. Hipolito Yrigoyen 18094",
   cp: null,
   phone: "(011)4233-2507",
   mobile: 1160458233,
   mail: "zonicaudio@hotmail.com",
   website: null,
   Instagram: "autoradiozonic"
  },
  {
   name: "ST DESIGN",
   lat: -37.3176925502665,
   lng: -59.1434476693094,
   country: "Argentina",
   city: "Tandil",
   province: "Buenos Aires",
   zone: "Buenos Aires",
   address: "Yrigoyen 1364",
   cp: 7000,
   phone: "(0249) 15469-1016",
   mobile: null,
   mail: "martin_cav@hotmail.com",
   website: null,
   Instagram: "stdesigntandil"
  },
  {
   name: "ACC AUDIOCAR Y EQUIPAMIENTOS",
   lat: -34.9248169516656,
   lng: -57.9711147000499,
   country: "Argentina",
   city: "La Plata",
   province: "Buenos Aires",
   zone: "Buenos Aires",
   address: "Av. 44 Nº 1369",
   cp: 1900,
   phone: "(0221) 470-2896",
   mobile: null,
   mail: "accaudiocarequipamiento@gmail.com",
   website: null,
   Instagram: "acc_audiocar_equipamiento"
  },
  {
   name: "LEO CARS EQUIPAMIENTOS",
   lat: -27.4362411538884,
   lng: -59.0039387046344,
   country: "Argentina",
   city: "Resistencia",
   province: "Chaco",
   zone: "Chaco",
   address: "Fotheringam 45",
   cp: null,
   phone: null,
   mobile: null,
   mail: "ipextreme_car@hotmail.com",
   website: null,
   Instagram: null
  },
  {
   name: "CARSOUND",
   lat: -34.6610113606735,
   lng: -58.5848622595829,
   country: "Argentina",
   city: "Cordoba",
   province: "Córdoba",
   zone: "Córdoba",
   address: "Bv los Granaderos 2573",
   cp: 5000,
   phone: "(0351) 477-6246",
   mobile: null,
   mail: "carsoundweb@gmail.com",
   website: null,
   Instagram: "carsound_cba"
  },
  {
   name: "LUCAS CAR AUDIO",
   lat: -32.4116414411424,
   lng: -63.2355230307256,
   country: "Argentina",
   city: "Villa Maria",
   province: "Córdoba",
   zone: "Córdoba",
   address: "Av. Alvear 397",
   cp: null,
   phone: "(0353) 461-4230",
   mobile: null,
   mail: "pabloromero78@hotmail.com",
   website: null,
   Instagram: "lucascaraudio.vm"
  },
  {
    name: "FRR ELECTRONICA",
    lat: -33.1519188972024,
    lng: -59.3197786866002,
    country: "Argentina",
    city: "Gualeguay",
    province: "Entre Ríos",
    zone: "Entre Ríos",
    address: "San Lorenzo 431",
    cp: 2840,
    phone: "+54 9 3444 443690",
    mobile: null,
    mail: "frrelectronica@hotmail.com",
    website: "https:\/\/facebook.com/frr.electronica\/",
    Instagram: "frrelectronica"
  },
  {
   name: "2L AUDIOCAR",
   lat: -32.9817733857659,
   lng: -68.7977874154403,
   country: "Argentina",
   city: "Maipú",
   province: "Mendoza",
   zone: "Mendoza",
   address: "Belgrano 712",
   cp: 5515,
   phone: "(0261) 481-2253",
   mobile: null,
   mail: "2laudiocar@gmail.com",
   website: null,
   Instagram: "2l_audiocar"
  }
 ]
